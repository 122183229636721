// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    targets: '.number-card',
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        // TODO: replace with bootstrap toggle
        // $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  $('a').on('click', function (event) {
    if (this.hash !== '') {
      event.preventDefault();

      var hash = this.hash;
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top,
        },
        800,
        function () {
          window.location.hash = hash;
        }
      );
    }
  });

  $('.header__select').on('click', function () {
    $('.header__select').toggleClass('open');
  });
  $('.header__select span').on('click', function (e) {
    if (
      !$(e.target).hasClass('current') &&
      $(e.target).attr('data-lang') !== 'english'
    ) {
      window.location.replace('/' + $(e.target).attr('data-lang'));
    } else if (
      !$(e.target).hasClass('current') &&
      $(e.target).attr('data-lang') === 'english'
    ) {
      window.location.replace('/');
    }
  });
  $('.header__select span').each(function () {
    if ('/' + $(this).attr('data-lang') + '/' === window.location.pathname) {
      $(this).addClass('current');
      $('.header__select').prepend($(this));
    } else if (
      $(this).attr('data-lang') === 'english' &&
      window.location.pathname === '/'
    ) {
      $(this).addClass('current');
      $('.header__select').prepend($(this));
      $('.intro__second-heading').addClass('english');
    }
  });
  if ('/' + 'spanish' + '/' === window.location.pathname) {
    $('.header__select span').each(function () {
      if ($(this).attr('data-lang') === 'spanish') {
        $(this).html('ESPAÑOL');
      } else if ($(this).attr('data-lang') === 'english') {
        $(this).html('INGLÉS');
      }
    });
    $('.header__button').html('CONTÁCTANOS');
    $('.footer__descroption-text').html(
      'Descargo de Responsabilidad Aquí: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis quam pretium, dignissim purus a, convallis libero. Quisque a sem gravida, ultrices massa pulvinar, rhoncus massa. Proin eget nibh nec purus aliquam ultrices eu sed tortor. Praesent tincidunt ante sit amet nunc condimentum tempus. Nullam convallis sapien sit amet mollis ultrices. Mauris vitae blandit nunc. Proin congue enim turpis, ut mollis nulla aliquet id. Praesent in ultrices dolor, non pharetra libero. Etiam elementum mollis blandit. Vestibulum pretium ex in lorem congue, ut laoreet just° scelerisque.'
    );
    $('.footer__descroption.copyright').html(
      '2021 Lucky Fortune, LLC. Todos los derechos reservados.'
    );
  } else if ('/' + 'hindi' + '/' === window.location.pathname) {
    $('.header__button').html('हमसे संपर्क करें');
  }

  $('.slider-section__slider').slick({
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    dots: true,
    centralMode: true,
    speed: 300,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
